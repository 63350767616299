import moment from "moment";
import React, { memo } from "react";
import { Field, Form } from "react-final-form";

import { InfoRounded as InfoIcon } from "@material-ui/icons";
import { Checkbox } from "@my-scoot/component-library-legacy";
import { ExlyInput, ExlyOfferingSelect } from "common/form";
import ExlyPriceInput from "common/form/ExlyMoneyInput";
import { DurationPickerField, TooltipTitle } from "./components/CustomFields";
import LimitedOfferShare from "../../modules/LimitedOfferTimeShare";
import { is_desktop } from "utils/Utils";
import constants from "../../../constants/constants";
import {
  getUserCurrencySymbol,
  isInternationalCurrency,
  isInternationalTimezone,
} from "../../../utils/AuthUtil";
import { useStyles } from "./index.styles";
import style from "./Style.module.css";
import useLimitedTimeOffer from "./useLimitedTimeOffer";
import { required } from "common/validate";
import { composeValidators } from "utils/validations";
import { getUserTimezone } from "utils/AuthUtil";
import timezones from "constants/timezones/AllTimezonesArray";
import { OfferingDesctiption } from "common/Components/OfferingSelect/HelperComponents";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT_12,
} from "constants/dateTime";
import { ExlyNote } from "common/Components/ExlyNote/ExlyNote";
import { isInstallmentsPlanType } from "schedule-v2/commonPages/Components/PaymentPlanWithInstallments/utils/PaymentPlanWithInstallments.utils";
import { PART_PAYMENT_FORM_KEYS } from "schedule-v2/commonPages/Components/PaymentPlanWithInstallments/PaymentPlanWithInstallments.data";
import Info from "@material-ui/icons/InfoRounded";

export const getGSTHelperText = (gst_status, gst_percentage) => {
  const gst_factor = 1 + (gst_percentage ?? 18) / 100;
  return [
    {
      text: "Price shown to the customer will be",
      factor: gst_status === constants.GST_STATUS.INCLUSIVE ? 1 : gst_factor,
    },
    {
      text: "Price excluding your GST will be",
      factor:
        gst_status === constants.GST_STATUS.INCLUSIVE ? 1 / gst_factor : 1,
    },
  ];
};

const hasAtmostTwoDecimalPlaces = (value) => /^\d*(\.\d{0,2})?$/.test(value);

export const LimitedOfferModalContent = memo(
  React.forwardRef((props, ref) => {
    const classes = useStyles({ is_desktop });

    const { showShareModal = true, forceShowForm = false } = props;

    const {
      isEdit,
      initialValues,
      recordData,
      offeringOptions,
      firstLimitedOffer,
      listingUuid,
      limitedOfferCreative,
      handleCreateOffer,
      DateField,
      selectedListing,
    } = useLimitedTimeOffer(props);

    const timeZone = timezones.all_timezones.find(
      (o) => o.value === getUserTimezone()
    ).label;

    const { gst_status, hideLayout } = props;
    const [placeholder, setPlaceHolder] = React.useState(null);

    const formRef = React.useRef(null);

    const validateOfferName = (offerName) => {
      let errorOffer;
      if (!offerName) {
        errorOffer = "Please enter a name for the offer";
      } else if (
        !/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/.test(offerName) &&
        (offerName.trim().length === 0 || !offerName.endsWith(" "))
      ) {
        errorOffer = "Invalid Name";
      }
      return errorOffer;
    };

    const validateDiscountPrice = (discountPrice, values) => {
      let errorDiscount;

      if (!discountPrice) {
        errorDiscount = "Required";
      } else if (!hasAtmostTwoDecimalPlaces(discountPrice)) {
        errorDiscount = "Please add price upto 2 decimals only.";
      } else if (
        values.listing &&
        (discountPrice > values.listing?.price_per_head ||
          (isEdit && discountPrice > values.listing?.listing_price))
      ) {
        errorDiscount = "Offer price should be less than offering price";
      } else if (
        values.listing &&
        (discountPrice < 0.5 * values.listing?.price_per_head ||
          (isEdit && discountPrice < 0.5 * values.listing?.listing_price))
      ) {
        errorDiscount = "Maximum discount can be 50% of the offering price!";
      }
      return errorDiscount;
    };

    const validateEndDate = (end_date, values) => {
      let errorEndDate;

      if (!end_date) {
        errorEndDate = "Required";
      } else if (new Date(end_date) < new Date(values.start_date)) {
        errorEndDate = "End date should be greater than start date";
      }
      return errorEndDate;
    };

    const validateDiscountPriceInternational = (
      discountPriceInternational,
      formState
    ) => {
      let errorDiscountInternational;

      if (!discountPriceInternational) {
        errorDiscountInternational = "Required";
      } else if (!hasAtmostTwoDecimalPlaces(discountPriceInternational)) {
        errorDiscountInternational = "Please add price upto 2 decimals only.";
      } else if (
        formState.listing &&
        discountPriceInternational > formState.listing?.price_international
      ) {
        errorDiscountInternational =
          "Offer price should be less than offering price";
      } else if (
        formState.listing &&
        (discountPriceInternational <
          0.5 * formState.listing?.price_international ||
          (isEdit &&
            discountPriceInternational <
              0.5 * formState.listing?.listing_price))
      ) {
        errorDiscountInternational =
          "Maximum discount can be 50% of the offering price!";
      }
      return errorDiscountInternational;
    };

    React.useImperativeHandle(ref, () => ({
      // returns { listingOfferData: { listing offer data response }, limitedOfferCreative: boolean }
      triggerSave() {
        if (!formRef.current) return;

        const { submit } = formRef.current;
        const { values: formState, valid } = formRef.current.getState();

        submit();
        return valid ? handleCreateOffer(formState).then((res) => res) : {};
      },
    }));

    return (
      <>
        {forceShowForm || !limitedOfferCreative ? (
          <Form
            initialValues={initialValues}
            onSubmit={!hideLayout ? handleCreateOffer : () => {}}
          >
            {({ handleSubmit, values, form }) => {
              formRef.current = form;
              const { listing } = values;

              return (
                <form onSubmit={handleSubmit}>
                  <div className={style.limitedContainer}>
                    {!hideLayout && (
                      <div className={style.limitedTitleContent}>
                        <div className={style.limitedTitle}>
                          Create Limited-Time Offers
                        </div>
                        <div
                          className={style.crossIcon}
                          onClick={() => props.handleClick()}
                        >
                          &times;
                        </div>
                      </div>
                    )}

                    <div className={classes.offerfeildContainer}>
                      <Field
                        name="listing"
                        component={ExlyOfferingSelect}
                        label="Select an Offering *"
                        mobileModalTitle="Select an Offering"
                        placeholder="Select Offering"
                        showStatus
                        fullWidth={true}
                        onChange={(value) => {
                          setPlaceHolder(
                            `Eg, ${Math.round(
                              value.price_per_head - value.price_per_head * 0.25
                            )} (original price - 25%)`
                          );
                        }}
                        renderSelectedValue={() =>
                          values.listing ? (
                            <OfferingDesctiption
                              {...values.listing}
                              isCondensed
                            />
                          ) : (
                            "Select Offering"
                          )
                        }
                        validate={(value) =>
                          !value && "Please select an offering to create offer"
                        }
                        options={offeringOptions}
                      />

                      {isInstallmentsPlanType(
                        listing?.[PART_PAYMENT_FORM_KEYS.payment_plan_type]
                      ) && (
                        <ExlyNote
                          className="m-0"
                          label={
                            <div>
                              <strong>Please note:</strong> This is a
                              Part-payment enabled offering. Limited-time offer
                              will apply to the one-time payment option only,
                              not the part payment.
                            </div>
                          }
                        />
                      )}

                      <Field
                        component={ExlyInput}
                        labelClassName={classes.inputLabel}
                        name="offerName"
                        label="Offer Name *"
                        placeholder="Eg, Flash Deal"
                        fullWidth
                        validate={validateOfferName}
                      />

                      {values?.listing && (
                        <>
                          <Field
                            name="INRPrice"
                            component={ExlyPriceInput}
                            label={
                              <TooltipTitle
                                title="Offer Price"
                                description="Set the new price you want the customers to pay for this offering"
                              />
                            }
                            type="number"
                            placeholder={placeholder}
                            fullWidth
                            symbol={getUserCurrencySymbol(
                              values.region === constants.domestic_region &&
                                !isInternationalCurrency()
                                ? constants.inr_currency
                                : constants.usd_currency
                            )}
                            validate={(value) =>
                              validateDiscountPrice(value, values)
                            }
                          />

                          {values.INRPrice > 0 &&
                          values.region === constants.domestic_region &&
                          form.getFieldState("INRPrice")?.valid &&
                          gst_status !== constants.GST_STATUS.INAPPLICABLE &&
                          !isInternationalTimezone() ? (
                            <div className={classes.gstInfo}>
                              <InfoIcon />
                              <div className={classes.infoContainer}>
                                {getGSTHelperText(
                                  gst_status,
                                  values?.listing?.metadata?.gst_percent
                                ).map((item) => (
                                  <div key={item.text}>
                                    {item.text}&nbsp;
                                    {constants.inr_currency}
                                    {Math.round(
                                      values.INRPrice * item.factor * 100
                                    ) / 100}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}
                      {values?.listing?.price_international > 0 ? (
                        <>
                          <div className={classes.salescheckbox}>
                            <Field
                              id="USDCheckbox"
                              name="USD"
                              type="checkbox"
                              component={Checkbox}
                              size="large"
                              onChange={(e) =>
                                form.change("USD", e.target.checked)
                              }
                            />
                            <label
                              htmlFor="USDCheckbox"
                              className={classes.checkboxtitle}
                            >
                              I want to set a different price for International
                              clients
                            </label>
                          </div>

                          {values.USD && (
                            <>
                              <Field
                                name="USDPrice"
                                component={ExlyPriceInput}
                                placeholder="Enter price"
                                type="number"
                                fullWidth
                                symbol="$"
                                validate={(value) =>
                                  validateDiscountPriceInternational(
                                    value,
                                    values
                                  )
                                }
                              />

                              {values.USDPrice > 0 &&
                              form.getFieldState("USDPrice")?.valid &&
                              gst_status !==
                                constants.GST_STATUS.INAPPLICABLE ? (
                                <div className={classes.gstInfo}>
                                  <InfoIcon />
                                  <div className={classes.infoContainer}>
                                    {getGSTHelperText(gst_status).map(
                                      (item) => (
                                        <div key={item.text}>
                                          {item.text}&nbsp;
                                          {constants.usd_currency}
                                          {Math.round(
                                            values.USDPrice * item.factor * 100
                                          ) / 100}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : null}

                      <TooltipTitle
                        title="Discount Period"
                        description="Select a time period for which the discount will be valid for all customers"
                      />

                      <DurationPickerField
                        label="Offer Start Date *"
                        datePickerProps={{
                          name: "start_date",
                          validate: (value) => !value && "Required",
                          parse: (date) => {
                            if (isEdit && recordData.status !== 0) return;

                            const todaysDate = new Date();

                            if (
                              date?.getDate() === todaysDate?.getDate() &&
                              date?.getFullYear() ===
                                todaysDate?.getFullYear() &&
                              date?.getMonth() === todaysDate?.getMonth()
                            ) {
                              const time = DateField(todaysDate, "fetchTime");
                              form.change(
                                "start_time",
                                time?.time.toLowerCase()
                              );
                              form.change(
                                "start_time_international",
                                time?.time.toLowerCase()
                              );
                            }

                            const formattedDate = moment(new Date(date)).format(
                              "YYYY-MM-DD"
                            );

                            values?.listing?.price_international > 0 &&
                              form.change(
                                "start_date_international",
                                formattedDate
                              );

                            return formattedDate;
                          },
                        }}
                        timePickerProps={{
                          name: "start_time",
                          parse: (value) => {
                            form.change("start_time_international", value);
                            return value;
                          },
                          uniqueId: `offer_start_time`,
                          validate: composeValidators(required, (value) => {
                            const { start_date } = values;
                            const isPast = moment(
                              `${start_date} ${value}`,
                              `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT_12}`
                            ).isBefore(moment());
                            if (isPast) return "Please pick time in future";
                          }),
                        }}
                      />

                      <DurationPickerField
                        label="Offer End Date *"
                        datePickerProps={{
                          name: "end_date",
                          validate: (value) => validateEndDate(value, values),
                          parse: (value) => {
                            const formattedDate = moment(
                              new Date(value)
                            ).format("YYYY-MM-DD");

                            values?.listing?.price_international > 0 &&
                              form.change(
                                "end_date_international",
                                formattedDate
                              );

                            return formattedDate;
                          },
                        }}
                        timePickerProps={{
                          name: "end_time",
                          uniqueId: `offer_end_time`,
                          parse: (value) => {
                            form.change("end_time_international", value);
                            return value;
                          },
                        }}
                      />
                      <div className={style.termsText}>
                        <p>{`** Default Timezone is ${timeZone}`}</p>
                      </div>
                    </div>

                    {!hideLayout && (
                      <div className={style.offerButton}>
                        <button type="submit" className={style.createButton}>
                          Create Offer
                        </button>
                        {!isEdit && (
                          <button
                            className={style.clearButton}
                            onClick={form.reset}
                          >
                            Clear
                          </button>
                        )}
                      </div>
                    )}

                    <div
                      className={`tw-mt-[10px] tw-text-[#493AB1] tw-flex tw-py-[8px] tw-px-[12px] tw-text-[14px] tw-bg-[#DCD9F2] tw-items-center tw-rounded-[5px] tw-gap-[8px]`}
                    >
                      <Info /> Maximum discount can be 50% of the offering
                      price!
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        ) : (
          <LimitedOfferShare
            setShowModal={limitedOfferCreative && showShareModal}
            firstOffer={firstLimitedOffer}
            listing={selectedListing}
            uid={listingUuid}
            showBroadcast
          />
        )}
      </>
    );
  })
);
